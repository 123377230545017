import { hmacsign } from 'oauth-sign'
import { v4 as uuidv4 } from 'uuid';
import './embed.scss'

function getCurrentTimestamp() {
  return Math.round(Date.now() / 1000)
}

if (typeof window.EASE === 'undefined') window.EASE = {}

// example call for testing
// EASE.launchLTILink({
//   firstName: 'Daniel',
//   lastName: 'Del Rio',
//   email:'ddelrio@easelearning.com',
//   organization: 'Ease Learning',
//   course: 'My Test Course',
//   ltiURL: 'https://api-staging.easelearning.com/lti-request/content/123/',
//   ltiConsumerKey: 'I99kKwmAIK',
//   ltiSharedSecret: 'RGaQHrqxpe',
//   userId: 'my-unique-identifier', // OPTIONAL: One will be generated if not provided but the user will be treated as a new user.
// })

window.EASE.launchLTILink = ({
  firstName,
  lastName,
  email,
  organization,
  course,
  ltiURL,
  ltiConsumerKey,
  ltiSharedSecret,
  userId = null,
  roles = 'learner',
  resource = null,
}, submit = true) => {
  const currentTimestamp = getCurrentTimestamp()

  let realUserId = userId
  if (realUserId === null) {
    realUserId = uuidv4()
  }

  let realResource = resource
  if (realResource === null) {
    realResource = window.location.href
  }

  const ltiPostParams = {
    context_id: course,
    context_label: course,
    context_title: course,
    context_type: 'CourseSection',
    launch_presentation_document_target: 'frame',
    launch_presentation_return_url: window.location.href,
    launch_presentation_locale: 'en-US',
    lis_course_section_sourcedid: course,
    lis_course_offering_sourcedid: course,
    lis_outcome_service_url: 'about:blank',
    lis_person_contact_email_primary: email,
    lis_person_name_family: lastName,
    lis_person_name_full: `${firstName} ${lastName}`,
    lis_person_name_given: firstName,
    lis_person_sourcedid: uuidv4(),
    lis_result_sourcedid: uuidv4(),
    lti_message_type: 'basic-lti-launch-request',
    lti_version: 'LTI-1p0',
    oauth_consumer_key: ltiConsumerKey,
    oauth_callback: 'about:blank',
    oauth_nonce: btoa(currentTimestamp.toString()),
    oauth_signature_method: 'HMAC-SHA1',
    oauth_timestamp: currentTimestamp,
    oauth_version: '1.0',
    resource_link_description: document.title,
    resource_link_id: realResource,
    resource_link_title: document.title,
    roles,
    tool_consumer_info_product_family_code: 'ease-embed',
    tool_consumer_info_version: '1.3.2',
    tool_consumer_instance_contact_email: 'sysadmin@easelearning.com',
    tool_consumer_instance_name: 'Skillways - Embed',
    tool_consumer_instance_url: 'https://designer.easelearning.com',
    tool_consumer_instance_description: organization,
    tool_consumer_instance_guid: organization,
    user_id: realUserId,
  };

  // handle query string parameters on the tool's address
  const signatureParams = { ...ltiPostParams }
  const ltiURLInterface = new URL(ltiURL)
  const ltiSearchParams = ltiURLInterface.searchParams
  for (const [key, value] of ltiSearchParams) {
    signatureParams[key] = value
  }
  const orderedSignatureParams = Object.keys(signatureParams).sort().reduce(
    (obj, key) => {
      obj[key] = signatureParams[key];
      return obj;
    },
    {}
  );
  const baseURL = `${ltiURLInterface.origin}${ltiURLInterface.pathname}`

  ltiPostParams.oauth_signature = hmacsign('POST', baseURL, orderedSignatureParams, ltiSharedSecret)

  const container = document.createElement('span')

  let html = `<form id="ltiLaunchForm" method="post" action="${ltiURL}">`

  Object.keys(ltiPostParams).map(key => {
    html += `<input type="hidden" name="${key}" value="${ltiPostParams[key]}"/>`
  })
  html += '</form>'

  container.innerHTML = html
  document.body.append(container)

  if (submit) document.getElementById('ltiLaunchForm').submit()
}
